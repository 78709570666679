import qs from "query-string";
import React from "react";
import uuid4 from "uuid/v4";

// TODO: move it in config
export const MOBILE_BREAKPOINT = 747;
export const DESKTOP_BREAKPOINT = 1230;

export const TOP_TYPE_EXCHANGE = "TOP_TYPE_EXCHANGE";
export const TOP_TYPE_AD = "TOP_TYPE_AD";

export function getPathParams() {
  const match = window.location.pathname.match(/^\/top\/in\/(\d+)\/?(\d+)?/);
  const params = {};

  if (match) {
    params.announce_id = match[1];
    params.campaign_id = match[2];
  }

  return params;
}

function queryStringArray(fieldName, values) {
  return values.reduce((prev, current) => {
    prev.push(
      encodeURIComponent(fieldName) + "=" + encodeURIComponent(current)
    );
    return prev;
  }, []);
}

export function stringQueryParams(params) {
  let stringParams = [];

  Object.keys(params).forEach(k => {
    if (params[k] !== undefined) {
      if (params[k] instanceof Array) {
        stringParams = stringParams.concat(queryStringArray(k, params[k]));
      } else {
        stringParams.push(
          encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
        );
      }
    }
  });

  return stringParams.join("&");
}

export function getQueryParams() {
  return qs.parse(window.location.search);
}

export function getUrlParams() {
  const pathParams = getPathParams();
  const qsParams = getQueryParams();
  return Object.assign(pathParams, qsParams);
}

export function assignQs(locationSearch, extra) {
  const params = qs.parse(locationSearch);
  Object.assign(params, extra);
  return qs.stringify(params);
}

export function chunk(arr, num) {
  const result = [];
  while (arr.length) {
    result.push(arr.splice(0, num));
  }
  return result;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}

export function withProps(WrappedComponent, props) {
  class WithProps extends React.Component {
    render() {
      return <WrappedComponent {...this.props} {...props} />;
    }
  }

  WithProps.displayName = `WithProps(${getDisplayName(WrappedComponent)})`;

  return WithProps;
}

export function makeScroll(el, options) {
  const isSmoothScrollSupported =
    "scrollBehavior" in document.documentElement.style;

  if (isSmoothScrollSupported) {
    el.scrollBy(options);
  } else {
    el.scrollBy(options.left, options.top);
  }
}

export function reachGoal(target, params, callback) {
  console.log("call reachGoal");
  console.log("yaCounter is not ready");
  console.log(target, params);
  callback && callback();
}

export function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function generateCookie() {
  return uuid4();
}

export function assignUrlParams(url, params) {
  const parsed = qs.parseUrl(url);
  Object.assign(parsed.query, params);
  const query = qs.stringify(parsed.query);
  return `${parsed.url}?${query}`;
}

export function appendUrlParam(url, name, value) {
  const param = `${name}=${encodeURIComponent(value)}`;

  if (url.indexOf("?") === -1) {
    return `${url}?${param}`;
  }

  const lastChar = url[url.length - 1];
  if (lastChar === "&") {
    return `${url}${param}`;
  }

  if (lastChar === "?") {
    return `${url}${param}`;
  }

  return `${url}&${param}`;
}

export const getTextLength = (text = "") => {
  // const LG = 120;
  const MD = 60;
  const SM = 30;
  const textLength = text.length;

  if (textLength <= SM) {
    return "size-title_sm";
  }

  if (textLength > SM && textLength <= MD) {
    return "size-title_md";
  }

  return "size-title_lg";
};

export function getImageUrl(image, size) {
  return image.replace("400_400", size);
}

export function getTeaserImages(image) {
  return {
    webp: image.replace(".jpeg", ".webp"),
    jpeg: image
  };
}

export function isMobile() {
  return window.innerWidth <= MOBILE_BREAKPOINT;
}

export function isDesktop() {
  return window.innerWidth > MOBILE_BREAKPOINT;
}

export function getSocialUrls(title, url) {
  url = window.location.origin + url;

  return {
    vk: "http://vk.com/share.php?url=" + url + "&title=" + title,
    twitter: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
    facebook:
      "https://www.facebook.com/sharer/sharer.php?u=#" + url + "&t=" + title,
    ok: `https://connect.ok.ru/offer?url=${url}&title=${title}`,
    telegram: `https://t.me/share/url?url=${url}&text=${title}`
  };
}

export function getTitle(newsTitle = "") {
  const type = getTopType();

  const titles = {
    TOP_TYPE_EXCHANGE: newsTitle
      ? `${newsTitle + " | Агрегатор 24СМИ"}`
      : "Агрегатор 24СМИ | Последние новости России и мира",
    TOP_TYPE_AD: newsTitle
      ? newsTitle + " | ADWILE"
      : "ADWILE | Популярные новости на основе ваших интересов"
  };

  return titles[type];
}

export function getFavicon() {
  const type = getTopType();
  // const fav = document.getElementById("favicon");
  const favicons = {
    TOP_TYPE_EXCHANGE: process.env.PUBLIC_URL + "/favicon.ico",
    TOP_TYPE_AD: process.env.PUBLIC_URL + "/favicon.ico"
  };

  return favicons[type];
}

export function getLinks(topId, isAdwile = false) {
  const type = getTopType();

  const links = {
    TOP_TYPE_EXCHANGE: {
      addSource: `https://partner.24smi.info/?utm_source=top_${topId}&utm_medium=add_source`,
      login: `https://partner.24smi.info/user/login/exchange/?utm_source=top_${topId}&utm_medium=login`
    },
    TOP_TYPE_AD: {
      addSource: `https://partner.24smi.info/advert/?utm_source=top_${topId}`,
      login: `https://partner.24smi.info/user/login/webmaster/?utm_source=top_${topId}`
    }
  };

  if (isAdwile) {
    return {
      addSource:
        "https://partner.adwile.com/advert?utm_source=top_24smi&utm_term=new_top",
      login: links[type].login
    };
  }

  return links[type] || {};
}

export function getTopType() {
  const domain = window.location.hostname;

  const adDomainsList = ["adwile.com", "advert.top.test"];

  if (adDomainsList.indexOf(domain) !== -1) {
    return TOP_TYPE_AD;
  }

  return TOP_TYPE_EXCHANGE;
}

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

function getUserLocale() {
  const n = navigator;

  if (n.languages && n.languages.length) {
    return n.languages[0];
  } else {
    return n.userLanguage || n.language || n.browserLanguage;
  }
}

export function getUserInfo() {
  let dpr = window.devicePixelRatio;
  dpr = dpr == null ? undefined : parseFloat(dpr.toFixed(4));

  const now = new Date();
  const nowUtc = Math.floor(now.getTime() / 1000);
  const tz = -now.getTimezoneOffset() * 60;

  return {
    psw: window.screen.width,
    psh: window.screen.height,
    pow: window.outerWidth,
    poh: window.outerHeight,
    pdpr: dpr,
    pdt: nowUtc,
    ptz: tz,
    pl: getUserLocale()
  };
}

export function addProto(url) {
  let { protocol } = window.location;

  if (protocol !== "https:" && protocol !== "http:") {
    protocol = "https:";
  }

  return `${protocol}//${url}`;
}

export function buildTeaserUrl(domain, path, query) {
  let url = path;

  if (domain) {
    url = `${addProto(domain)}${url}`;
  }

  for (let name in query) {
    const value = query[name];
    if (value != null) {
      if (typeof value === "number" && isNaN(value)) {
        continue;
      }

      url = appendUrlParam(url, name, value);
    }
  }

  return url;
}

export function getPageTimerReloadSearch(configuration) {
  const newSearch = assignQs(window.location.search, {
    reload: true,
    timer_reload: undefined,
    click_id: configuration.click_id,
    domain: configuration.domain || "",
    prev_campaign_id: configuration.prev_campaign_id
  });

  return newSearch;
}

export function getLoadTopParams(num, config) {
  const params = Object.assign({}, config, {
    num,
    tid: config.tid
  });

  return params;
}

export function replaceImageDomain(image) {
  const newDomain = `${process.env.REACT_APP_IMAGE_HOST || ""}`;
  if (newDomain) {
    return image.replace("//img.24smi.net", newDomain);
  }
  return image;
}

export function removeTeaserDomain(url) {
  return url
    .replace("https://ex.24smi.info", "")
    .replace("https://adwile.com", "");
}

export function addJs(src) {
  let t = document.getElementsByTagName("script")[0];
  let s = document.createElement("script");
  s.type = "text/javascript";
  s.src = src;
  s.async = true;
  t.parentNode.insertBefore(s, t);
}

export function getTopUrl(config) {
  return assignUrlParams("/top", {
    click_id: config.click_id,
    domain: config.domain || "",
    prev_campaign_id: config.prev_campaign_id,
    wb: 1,
    rnd: Math.random()
  });
}

export function toLocation(url) {
  const a = document.createElement("a");
  a.href = url;
  return a;
}

export function removeDomain(url) {
  const loc = toLocation(url);
  return loc.pathname + loc.search + loc.hash;
}
