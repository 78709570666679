import React from "react";
import { getCookie, generateCookie } from "utils";

function getRootDomain(domain) {
  if (domain === "localhost") return "localhost";

  const parts = domain.split(".").reverse();
  return "." + parts[1] + "." + parts[0];
}

const SMI_IFRAME_SRC = process.env.REACT_APP_SMI_IFRAME_SRC;
const ADWILE_IFRAME_SRC = process.env.REACT_APP_ADWILE_IFRAME_SRC;

const SMI_HOST = process.env.REACT_APP_SMI_HOST;
const ADWILE_HOST = process.env.REACT_APP_ADWILE_HOST;

export default class CookieFrame extends React.Component {
  constructor(props) {
    super(props);

    this.currentHost = getRootDomain(window.location.hostname);
    this.iframeSrcList = [];

    if (this.currentHost === ADWILE_HOST) {
      this.iframeSrcList = [SMI_IFRAME_SRC];
    } else if (this.currentHost === SMI_HOST) {
      this.iframeSrcList = [ADWILE_IFRAME_SRC];
    } else {
      this.iframeSrcList = [SMI_IFRAME_SRC, ADWILE_IFRAME_SRC];
    }

    this.uid = getCookie("uid");

    if (!this.uid) {
      this.uid = generateCookie();

      document.cookie = `uid=${this.uid}; domain=${this.currentHost}; path=/`;
    }
  }

  onLoad = e => {
    e.target.contentWindow.postMessage(
      { action: "SET_COOKIE", uid: this.uid },
      "*"
    );
  };

  render() {
    const style = {
      display: "none",
      position: "absolute",
      left: "-99999px",
      top: "0",
      overflow: "hidden",
      border: "0"
    };

    return (
      <React.Fragment>
        {this.iframeSrcList.map(iframeSrc => (
          <iframe
            title={iframeSrc}
            src={iframeSrc}
            key={iframeSrc}
            onLoad={this.onLoad}
            style={style}
          />
        ))}
      </React.Fragment>
    );
  }
}
