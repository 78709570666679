export default {
  231: {
    back_redirect: {
      advert: "top",
      exchange: "top",
      no_news: "top"
    },
  },
  216: {
    feed_news_click: "reload",
    feed_news_click_timeout: 500,
    main_news_click: "reload",
    main_news_click_timeout: 500,
    back_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top"
    },
    inactive_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top",
      skip: "main_click",
      timeout: 15 * 1000
    }
  },
  217: {
    feed_news_click: "reload",
    feed_news_click_timeout: 500,
    main_news_click: "reload",
    main_news_click_timeout: 500,
    back_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top"
    },
    inactive_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top",
      skip: "main_click",
      timeout: 15 * 1000
    }
  },
  218: {
    feed_news_click: "reload",
    feed_news_click_timeout: 500,
    main_news_click: "reload",
    main_news_click_timeout: 500,
    back_redirect: {
      advert: "",
      exchange: "",
      no_news: ""
    },
    inactive_redirect: {
      advert: "top",
      exchange: "top",
      no_news: "top",
      skip: "main_click",
      timeout: 15 * 1000
    }
  },
  222: {
    feed_news_click: "reload",
    feed_news_click_timeout: 500,
    main_news_click: "reload",
    main_news_click_timeout: 500,
    back_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top"
    },
    inactive_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top",
      skip: "main_click",
      timeout: 15 * 1000
    }
  },
  220: {
    feed_news_click: "reload",
    feed_news_click_timeout: 500,
    main_news_click: "reload",
    main_news_click_timeout: 500,
    back_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top"
    },
    inactive_redirect: {
      advert: "top",
      exchange: "teaser",
      no_news: "top",
      skip: "main_click",
      timeout: 15 * 1000
    }
  }

};
